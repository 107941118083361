import { FragmentType, getFragmentData } from '@/lib/gql';
import { UserRoles, UserType } from '@/lib/gql/graphql';
import { UserInfo } from '@/lib/auth';

import { UserModelFragment } from './data-fragment';
export namespace User {
  export function isHuman(userParam: FragmentType<typeof UserModelFragment>) {
    const user = getFragmentData(UserModelFragment, userParam);
    return user.details.type === UserType.User;
  }

  export namespace Role {
    export function getDisplayName(role: UserRoles) {
      switch (role) {
        case UserRoles.Admin: {
          return 'Admin';
        }
        case UserRoles.AdminCompliance: {
          return 'Compliance Admin';
        }
        case UserRoles.AdminReadonly: {
          return 'Admin (Readonly)';
        }
        case UserRoles.Customer: {
          return 'User';
        }
      }
    }

    export function isAdmin(roles: (UserRoles | string)[]) {
      return roles.includes(UserRoles.Admin);
    }

    export function isAdminCompliance(roles: (UserRoles | string)[]) {
      return roles.includes(UserRoles.AdminCompliance);
    }

    export function isCustomer(roles: (UserRoles | string)[]) {
      return roles.includes(UserRoles.Customer);
    }

    /**
     * Defines which roles can be assigned to users based on entitlements
     * In this case, Admin read-only is not assignable
     */
    export function getAssignableRoles(user: UserInfo) {
      if (user.permissions['network-pm'].canAccess || user.permissions['network-compliance'].canAccess) {
        return [UserRoles.Admin, UserRoles.AdminCompliance, UserRoles.Customer];
      }
      return [UserRoles.Admin, UserRoles.Customer];
    }
  }
}

'use client';

import { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';

export function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
  // Initialize state with a function to avoid unnecessary initial value computation
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = secureLocalStorage.getItem(key);
      // Only return initialValue if item is null, not if it's an empty object/array
      return item ? JSON.parse(item.toString()) : initialValue;
    } catch (err) {
      console.error(`Error reading secureLocalStorage key "${key}":`, err);
      return initialValue;
    }
  });

  // Set initial value in localStorage if it doesn't exist
  useEffect(() => {
    if (typeof window !== 'undefined' && !secureLocalStorage.getItem(key)) {
      secureLocalStorage.setItem(key, JSON.stringify(initialValue));
    }
  }, [key, initialValue]);

  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      if (typeof window !== 'undefined') {
        secureLocalStorage.setItem(key, JSON.stringify(value));
      }
    } catch (err) {
      console.error(`Error setting secureLocalStorage key "${key}":`, err);
    }
  };

  return [storedValue, setValue];
}

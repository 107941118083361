import { graphql } from '@/lib/gql';

export const AddressModelFragment = graphql(`
  fragment AddressModel on Address {
    id
    balanceAsCoin: balance
    balanceUsd: balance(asFiat: USD)
    addressHash: publicKey
    asset {
      id
      createdAt
      symbol
      name
      gasTrackerUri
      rateUsd: rate(withFiat: USD)
      decimals
      ...AssetModel
      ...AssetIcon

      baseAsset {
        id
        createdAt
        symbol
        name
        gasTrackerUri
        rateUsd: rate(withFiat: USD)
        decimals
        ...AssetModel
        ...AssetIcon
      }
    }
  }
`);

export const VaultAddresses = graphql(`
  fragment VaultAddresses on Vault {
    id
    details {
      name
    }
    visibleAddresses: visibleAssets {
      ...AddressModel
    }
  }
`);

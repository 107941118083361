import { NextRequest, NextResponse } from 'next/server';

import { LinkTo } from '@/lib/links';
import { TermsVersion } from '@/lib/gql/graphql';

import { Env } from '../env';

import { getUserInfoFromCookies } from './read-cookies';
import { Roles, UserInfo, UserPlan } from './user-info';

// Add a constant for paths that should bypass middleware checks
const BYPASS_PATHS = [
  '/terms-and-conditions/update',
  '/auth/sign-out',
  // Add any other paths that should bypass the middleware
];

export function permissionsMiddleware(request: NextRequest): NextResponse<unknown> | null {
  // this will bypass the middleware for the paths in the BYPASS_PATHS array to avoid infinite redirects
  const shouldBypass = BYPASS_PATHS.some((path) => request.nextUrl.pathname.endsWith(path));

  if (shouldBypass) {
    return null;
  }
  try {
    const [, user] = getUserInfoFromCookies(request.cookies);
    if (!user) {
      return null;
    }

    // Check if the user has signed the terms of service
    const hasSignedTerms = checkSignedTermsAndConditions({ user, request });
    if (hasSignedTerms) {
      return hasSignedTerms;
    }

    // Check if the user has signed the latest terms of service
    const hasLatestProductTerms = checkHaveSignedProductLatestTerms({ user, request });
    if (hasLatestProductTerms) {
      return hasLatestProductTerms;
    }

    // Check if the user has access to specific products
    const hasAccessToProducts = checkEntitlementsAccess({ user, request });
    if (hasAccessToProducts) {
      return hasAccessToProducts;
    }

    return null;
  } catch (err) {
    console.error(err, 'middleware crashed');
    return NextResponse.redirect(new URL('/auth/sign-out', request.url));
  }
}

function checkEntitlementsAccess({ user, request }: { user: UserInfo; request: NextRequest }) {
  const response404 = NextResponse.redirect(new URL('/404', request.url));

  if (
    getSectionRegExp('compliance').test(request.nextUrl.pathname) &&
    !user.permissions['network-compliance'].canAccess
  ) {
    return response404;
  } else if (getSectionRegExp('vaults').test(request.nextUrl.pathname) && !user.permissions.vaults.canAccess) {
    return response404;
  } else if (getSectionRegExp('reports').test(request.nextUrl.pathname) && !user.permissions.reports.canAccess) {
    return response404;
  } else if (getSectionRegExp('dapps').test(request.nextUrl.pathname) && !user.permissions.dapps.canAccess) {
    return response404;
  } else if (
    getSectionRegExp('admin/settings').test(request.nextUrl.pathname) &&
    !(user.role === Roles.Admin || user.role === Roles.AdminCompliance)
  ) {
    return response404;
  }
}

function getSectionRegExp(
  section:
    | 'getting-started/vaults'
    | 'getting-started/network'
    | 'getting-started/flow'
    | 'compliance'
    | 'vaults'
    | 'reports'
    | 'dapps'
    | 'admin/settings',
) {
  return new RegExp(`^(\\/[a-z]{2})?\\/${section}`);
}

type TermsRedirectConfig = {
  plan: UserPlan;
  version: TermsVersion;
  redirect: string;
};

const termsRedirectConfigs: TermsRedirectConfig[] = [
  {
    plan: UserPlan.PM,
    version: TermsVersion.NetworkPmV1,
    redirect: LinkTo.gettingStartedNetwork(),
  },
  {
    plan: UserPlan.Underlying,
    version: TermsVersion.NetworkUcV1,
    redirect: LinkTo.gettingStartedNetwork(),
  },
  {
    plan: UserPlan.Vault,
    version: TermsVersion.VaultV1,
    redirect: LinkTo.gettingStartedVaults(),
  },
  {
    plan: UserPlan.Flow,
    version: TermsVersion.FlowV1,
    redirect: LinkTo.gettingStartedFlow(),
  },
];

function checkHaveSignedProductLatestTerms({ user, request }: { user: UserInfo; request: NextRequest }) {
  if (request.nextUrl.pathname.includes('getting-started')) {
    return null;
  }

  const latestProductTermsVersion = Env.termsAndConditions.platformVersion;

  const latestUserPlatformTerms = user.productSignedTerms?.find(
    (term) => term.product.toLowerCase() === UserPlan.Platform,
  );

  if (!latestUserPlatformTerms || latestUserPlatformTerms.version !== latestProductTermsVersion) {
    return NextResponse.redirect(new URL(LinkTo.termsAndConditionsUpdate(), request.url));
  }

  return null;
}

function checkSignedTermsAndConditions({ user, request }: { user: UserInfo; request: NextRequest }) {
  const isGettingStartedSections = new Set([
    getSectionRegExp('getting-started/vaults').test(request.nextUrl.pathname),
    getSectionRegExp('getting-started/network').test(request.nextUrl.pathname),
    getSectionRegExp('getting-started/flow').test(request.nextUrl.pathname),
  ]);

  const signedTerms = user.signedTerms;

  const redirectConfigs = getRedirectConfigs(user.plans, signedTerms);

  if (!isGettingStartedSections.has(true) && redirectConfigs.length > 0) {
    // Use the first redirect config for the initial redirect
    const initialRedirectConfig = redirectConfigs[0];
    const redirectUrl = new URL(initialRedirectConfig.redirect, request.url);

    if (request.url !== redirectUrl.href) {
      return redirectToGettingStarted(redirectUrl.href, request.url);
    }
  }

  if (isGettingStartedSections.has(true)) {
    // If the user is already on a getting-started page, prepare for the next redirect
    const nextRedirectConfig = getNextRedirectConfig(request.nextUrl.pathname, redirectConfigs);

    if (nextRedirectConfig) {
      const nextRedirectUrl = new URL(nextRedirectConfig.redirect, request.url);
      return redirectToGettingStarted(nextRedirectUrl.href, request.url);
    }
  }

  return null;
}

function getRedirectConfigs(userPlans: UserPlan[], signedTerms: TermsVersion[] | null): TermsRedirectConfig[] {
  if (!signedTerms) {
    return termsRedirectConfigs.filter((config) => userPlans.includes(config.plan));
  }

  return termsRedirectConfigs.filter(
    (config) => userPlans.includes(config.plan) && !signedTerms.includes(config.version),
  );
}

function redirectToGettingStarted(url: string, requestUrl: string): NextResponse<unknown> {
  const redirectUrl = new URL(url, requestUrl);
  return NextResponse.redirect(redirectUrl);
}

function getNextRedirectConfig(
  currentPath: string,
  redirectConfigs: TermsRedirectConfig[],
): TermsRedirectConfig | null {
  const currentIndex = redirectConfigs.findIndex((config) => currentPath.includes(config.redirect));
  return currentIndex >= 0 && currentIndex < redirectConfigs.length - 1 ? redirectConfigs[currentIndex + 1] : null;
}

import { graphql } from '@/lib/gql';

export const ComplianceIdentityModelFragment = graphql(`
  fragment ComplianceIdentityModel on Identity {
    id
    details {
      displayName
      status
    }
  }
`);

import { FragmentType, getFragmentData } from '@/lib/gql';

import { AddressModelFragment } from './data-fragment';
export * from './data-fragment';

type Address = FragmentType<typeof AddressModelFragment>;

export namespace Address {
  /**
   * @returns whether no addresses in the provided list belong to the given asset
   */
  export function noAddressesBelongsToAsset(addresses: Array<Address>, assetId: string): boolean {
    return addresses.every((addressParam) => {
      const addr = getFragmentData(AddressModelFragment, addressParam);
      if (!addr) return true;
      return addr.asset.id !== assetId;
    });
  }
}

import { NetworkName } from '@/lib/gql/graphql';

export const VAULT_SETTINGS_TABS = ['reshare', 'assets'] as const;
export type VaultSettingsTab = (typeof VAULT_SETTINGS_TABS)[number];

export const ACCOUNT_SETTINGS_TABS = ['apiKeys'] as const;
export type AccountSettingsTab = (typeof ACCOUNT_SETTINGS_TABS)[number];
export const MANAGE_COMPLIANCE_PROFILE_TABS = ['thresholdSettings', 'userAccess'] as const;
export type ManageComplianceProfileTab = (typeof MANAGE_COMPLIANCE_PROFILE_TABS)[number];

export const ORGANISATION_TABS = ['users', 'invites', 'api-keys', 'governance'] as const;
export type OrganisationTab = (typeof ORGANISATION_TABS)[number];

export const LinkTo = {
  root() {
    return '/';
  },
  support() {
    return '/support';
  },
  gettingStarted() {
    return '/getting-started';
  },
  gettingStartedVaults() {
    return '/getting-started/vaults';
  },
  gettingStartedNetwork() {
    return '/getting-started/network';
  },
  gettingStartedFlow() {
    return '/getting-started/flow';
  },
  signOut() {
    return '/auth/sign-out';
  },
  vaultCreate() {
    return `/vaults/new`;
  },
  vaultList(args?: { searchParams?: Record<string | 'query', string> }) {
    const urlSearchParams = new URLSearchParams(args?.searchParams ?? {});
    return `/vaults${getSearchParamString(urlSearchParams)}`;
  },
  vaultDetails({ vaultId }: { vaultId: string }) {
    return `/vaults/${vaultId}`;
  },
  vaultSettings({ vaultId, tab }: { vaultId: string; tab: VaultSettingsTab }) {
    return `/vaults/${vaultId}/settings/${tab}`;
  },
  vaultActivity({ vaultId }: { vaultId: string }) {
    return `/vaults/${vaultId}/activity`;
  },
  vaultAddressDetails({ vaultId, addressId }: { vaultId: string; addressId: string }) {
    return `/vaults/${vaultId}/assets/${addressId}`;
  },
  activityDetails({ activityId }: { activityId: string }) {
    return `/vaults/activity/${activityId}`;
  },
  vaultActivityDetails({ vaultId, activityId }: { vaultId: string; activityId: string }) {
    return `/vaults/${vaultId}/activity/${activityId}`;
  },
  addressActivityDetails({
    vaultId,
    addressId,
    activityId,
  }: {
    vaultId: string;
    addressId: string;
    activityId: string;
  }) {
    return `/vaults/${vaultId}/assets/${addressId}/activity/${activityId}`;
  },
  transferCreate({
    isMultiVault = false,
    ...params
  }: {
    vaultId?: string;
    network?: NetworkName;
    addressId?: string;
    isMultiVault?: boolean;
    toAddress?: string;
    ioNetworkId?: string;
  } = {}) {
    const searchParams = new URLSearchParams(Object.entries(params).filter(([, value]) => !!value));

    if (isMultiVault) {
      searchParams.set('isMultiVault', 'true');
    }

    return `/vaults/transfers/new${getSearchParamString(searchParams)}`;
  },
  reports() {
    return `/reports`;
  },
  reportDetails({ reportId }: { reportId: string }) {
    return `/reports/${reportId}`;
  },
  settings() {
    return '/settings';
  },
  profile() {
    return '/profile';
  },
  createApiKey() {
    return '/admin/settings/api-keys/new';
  },
  compliance() {
    return '/compliance';
  },
  complianceClientDetails({
    clientId,
    searchParams = {},
  }: {
    clientId: string;
    searchParams?: Record<string, string>;
  }) {
    const urlSearchParams = new URLSearchParams(searchParams);
    return `/compliance/clients/${clientId}${getSearchParamString(urlSearchParams)}`;
  },
  complianceProfileReport({ clientId }: { clientId: string }) {
    return `/compliance/clients/${clientId}/report`;
  },
  complianceProfileHistory({ clientId }: { clientId: string }) {
    return `/compliance/clients/${clientId}/history`;
  },
  complianceProfileManage({ clientId }: { clientId: string }) {
    return `/compliance/clients/${clientId}/manage`;
  },
  complianceProfileThresholdSettings({ clientId }: { clientId: string }) {
    return `/compliance/clients/${clientId}/manage/thresholdSettings`;
  },
  complianceProfileUserAccess({ clientId }: { clientId: string }) {
    return `/compliance/clients/${clientId}/manage/userAccess`;
  },
  complianceFlags(args?: { searchParams?: Record<string, string> }) {
    const urlSearchParams = new URLSearchParams(args?.searchParams ?? {});
    return `/compliance/flags${getSearchParamString(urlSearchParams)}`;
  },
  complianceFlagDetails({
    flagId,
    from,
    searchParams = {},
  }: {
    flagId: string;
    from?: 'client' | 'all-flags' | 'dashboard';
    searchParams?: Record<string, string>;
  }) {
    const urlSearchParams = new URLSearchParams(searchParams);
    if (from) {
      urlSearchParams.set('from', from);
    }

    return `/compliance/flags/${flagId}${getSearchParamString(urlSearchParams)}`;
  },
  complianceActivityDetails({ activityId }: { activityId: string }) {
    return `/compliance/activity/${activityId}`;
  },

  addressBook(args?: { searchParams?: Record<string | 'query' | 'toggle', string> }) {
    const urlSearchParams = new URLSearchParams(args?.searchParams ?? {});
    return `/address-book${getSearchParamString(urlSearchParams)}`;
  },

  addressBookContact({ addressBookId }: { addressBookId: string }) {
    return `/address-book/${addressBookId}`;
  },

  addressBookAddAddress({ addressBookId }: { addressBookId: string }) {
    return `/address-book/${addressBookId}/add-address`;
  },

  networkDirectory(args?: { searchParams?: Record<string | 'query', string> }) {
    const urlSearchParams = new URLSearchParams(args?.searchParams ?? {});
    return `/network-directory${getSearchParamString(urlSearchParams)}`;
  },
  networkDirectoryCreateContact() {
    return '/network-directory/contacts/new';
  },

  dapps(args?: { searchParams?: Record<string | 'query', string> }) {
    const urlSearchParams = new URLSearchParams(args?.searchParams ?? {});
    return `/dapps${getSearchParamString(urlSearchParams)}`;
  },
  dapp({ searchParams }: { searchParams?: Record<string, string> }) {
    const urlSearchParams = new URLSearchParams(searchParams);
    return `/dapp${getSearchParamString(urlSearchParams)}`;
  },
  walletConnect({ path, searchParams }: { path: string; searchParams?: Record<string, string> }) {
    const urlSearchParams = new URLSearchParams(searchParams);
    return `${path}${getSearchParamString(urlSearchParams)}`;
  },

  adminSettingsRoot() {
    return '/admin/settings';
  },
  adminSettings({ tab, searchParams }: { tab: OrganisationTab; searchParams?: Record<string, string> }) {
    const urlSearchParams = new URLSearchParams(searchParams);
    return `/admin/settings/${tab}${getSearchParamString(urlSearchParams)}`;
  },
  adminSettingsUserDetails({ userId }: { userId: string }) {
    return `/admin/settings/users/${userId}`;
  },
  adminSettingsInvitationDetails({ invitationid }: { invitationid: string }) {
    return `/admin/settings/invites/${invitationid}`;
  },
  adminSettingsInviteFlow() {
    return '/admin/settings/invites/new';
  },
  adminGovernanceSetup() {
    return '/admin/settings/governance/setup';
  },
  adminGovernanceVault() {
    return '/admin/settings/governance/vault';
  },
  adminGovernanceVaultSettings() {
    return '/admin/settings/governance/vault-settings';
  },
  adminGovernanceActivityDetails({ activityId }: { activityId: string }) {
    return `/admin/settings/governance/activity/${activityId}`;
  },
  termsAndConditionsUpdate() {
    return '/terms-and-conditions/update';
  },
};

export function getSearchParamString(searchParams: URLSearchParams) {
  if ([...searchParams.entries()].length > 0) {
    return `?${searchParams.toString()}`;
  }

  return '';
}

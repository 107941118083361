import { graphql } from '@/lib/gql';

export const CryptoAddressFragment = graphql(`
  fragment CryptoAddress on CryptoAddress {
    id
    details {
      address
      alias
      chainType
      networkName
    }
    identity {
      ...IdentityAddress
    }
  }
`);

export const IdentityAddressFragment = graphql(`
  fragment IdentityAddress on Identity {
    id
    details {
      displayName
      type
      ... on CorporateDetails {
        __typename
        companyName
        website
      }
      ... on IndividualDetails {
        __typename
        legalName
      }
    }
    cryptoAddresses {
      id
      details {
        address
        alias
        networkName
      }
    }
  }
`);
